import React from "react";
import Logo from "../images/logos/ugec-logo-white.svg";

const Header = () => {
  return (
    <header class="bg-gradient-to-r from-pacBlue to-pacLightBlue ">
      <div class="relative mx-auto max-w-7xl pt-4 pb-4 ">
        <div class="flex items-center justify-between pl-4 pr-4 md:px-0 ">
          <a href="/" class="w-2/3 md:w-1/3">
            <img class="hover:opacity-80" src={Logo} />
          </a>

          <div class=" p-5 rounded-xl flex justify-between">
            <div class="">
              <a href="/offset-residential">
                {" "}
                <svg
                  class="mx-auto h-10 text-white hover:opacity-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 400 400"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M199.5,17.2L10.5,181h37.8v201.6H100V244h80.6v138.6h170.1V181h37.8L199.5,17.2z M300.3,338.5H231V244h69.3
                    V338.5z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-auto text-xs font-medium text-white">
                  Residential
                </span>
              </a>
            </div>
            <div class="ml-4">
              <a href="/offset-commercial">
                <svg
                  class="mx-auto h-10 text-white hover:underline hover:opacity-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M24 24h-23v-16h6v-8h11v12h6v12zm-12-5h-3v4h3v-4zm4 0h-3v4h3v-4zm6 0h-2v4h2v-4zm-17 0h-2v4h2v-4zm11-5h-2v2h2v-2zm-5 0h-2v2h2v-2zm11 0h-2v2h2v-2zm-17 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm-6 0h-2v2h2v-2zm11-4h-2v2h2v-2zm-5 0h-2v2h2v-2zm5-4h-2v2h2v-2zm-5 0h-2v2h2v-2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-auto text-xs font-medium text-white">
                  Business
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
