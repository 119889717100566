import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ccpa = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>United Green Energy Credits | CCPA</title>

        <meta
          name="description"
          content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
        />
        <link
          rel="icon"
          href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
        />
      </Helmet>
      <Header />
      <div class="max-w-7xl pb-5 mx-auto">
        <div class="px-4 text-left mx-auto relative bg-lightYellow pt-10 pb-20 ">
          <div class="max-w-7xl mx-auto">
            <h2 class="uppercase text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              United Green Energy Credits ENERGY STATEMENT OF CALIFORNIA
              CONSUMER PRIVACY ACT (CCPA) COMPLIANCE
            </h2>
            <p class="text-base  tracking-loose">
              At United Green Energy Credits Energy (“United Green Energy
              Credits”), we take your privacy seriously. United Green Energy
              Credits’s general privacy policies are published on its website at{" "}
              <a href="/terms/english" class="text-orange">
                https://ugecredits.com/terms-of-use/
              </a>{" "}
              This Statement of CCPA Compliance (the “Statement”) provides
              additional information regarding how United Green Energy Credits
              complies with the California Consumer Privacy Act (CCPA). If you
              are a United Green Energy Credits customer, by using or accessing
              our website or services you acknowledge that you accept the
              practices and policies outlined in this Statement. For
              California-based consumers, this Statement provides the
              information for you to exercise your privacy rights under the
              CCPA.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              About Personal Information and Privacy
            </h2>
            <p class="text-base  tracking-loose">
              This CCPA Addendum (“Addendum”) forms part of the Terms of Service
              or other written or electronic agreement(s) between United Green
              Energy Credits and its customers for the provision of products
              and/or services by United Green Energy Credits to its Customers
              (the “Agreement”). This Addendum shall reflect the parties’
              agreement with regard to the processing of Personal Data (as
              defined below) in the performance of the Agreement. By executing
              this Addendum, Customer enters into this Addendum on behalf of
              itself and in the name and on behalf of its Affiliates. For the
              purposes of this Addendum, and except where indicated otherwise,
              the term “Customer” shall mean the organization entering into this
              Addendum and shall include its Affiliates, as applicable. Customer
              and United Green Energy Credits may be referred to in this
              Addendum individually as a “party” or jointly as the “parties.”
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              HOW TO EXECUTE THIS ADDENDUM:
            </h2>
            <p class="text-base  tracking-loose">
              To execute this Addendum, Customer must:{" "}
              <a
                href="https://imgix.cosmicjs.com/20f22650-a9e8-11ec-bd4d-d755cb3d1844-CCPA-3-22-22.pdf"
                target="blank"
                class="text-orange-400 font-extrabold hover:text-orange-600"
              >
                Download the PDF version{" "}
              </a>{" "}
              of the Addendum for completion. Fill in the information requested
              in the signature block and any areas requesting Customer’s
              information; and Send the signed Addendum to United Green Energy
              Credits Energy by email to UnitedGreenEnergy@uetllc.com indicating
              Customer’s full legal name and whether Customer is a current
              customer or prospective customer of United Green Energy Credits.
              If accepted, United Green Energy Credits will return the fully
              executed Addendum to Customer. This Addendum will not become
              effective until: (i) the Addendum is fully executed and returned
              to Customer; and (ii) the parties have entered into an Agreement
              for United Green Energy Credits’s products and/or services.
            </p>

            {""}

            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Defined Terms
            </h2>

            <p class="text-base  tracking-loose">
              Terms used but not defined in the Addendum, such as “business
              purpose”, “commercial purpose”, “consumer”, “processing”,
              “business”, “sell”, “selling”, “sale” and “verifiable consumer
              request”, will have the same meaning as set forth in California
              Civil Code Section 1798.140. Bracketed numbers are references to
              related sections of the California Civil Code. In addition,
              capitalized terms used in the Addendum shall have the following
              meanings:
            </p>
            <p class="text-base  tracking-loose">
              “CCPA” means the California Consumer Privacy Act, California Civil
              Code Sections 1798.100-1798.199.
            </p>
            <p class="text-base  tracking-loose">
              “Personal Information” means “personal information” as defined in
              the CCPA and within the scope of the CCPA.
            </p>
            <p class="text-base  tracking-loose">
              “Services” means any services to be performed by United Green
              Energy Credits under the Agreement.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Applicability
            </h2>
            <p class="text-base  tracking-loose">
              The Addendum applies to the processing of Personal Information
              within the scope of the CCPA in the course of providing Services
              to the Consumer and/or Company.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Effective Date
            </h2>
            <p class="text-base  tracking-loose">
              United Green Energy Credits makes the commitments in the Addendum
              effective on the later of (a) January 1, 2020, the operative date
              of the CCPA [1798.198], or (b) the date United Green Energy
              Credits begins to process Personal Information on behalf of
              Consumer and/or Company.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Prohibitions
            </h2>
            <p class="text-base  tracking-loose">
              United Green Energy Credits is prohibited from: (a) selling the
              Personal Information; (b) retaining, using, or disclosing the
              Personal Information for any purpose other than for the specific
              purpose of performing the Services or as otherwise permitted by
              the CCPA, including retaining, using, or disclosing the Personal
              Information for a commercial purpose other than providing the
              Services, and (iii) retaining, using, or disclosing the Personal
              Information outside of the direct business relationship between
              the United Green Energy Credits and Customer and/or Company.
              [1798.140(v)&(w)]
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Certification
            </h2>
            <p class="text-base  tracking-loose">
              United Green Energy Credits certifies that United Green Energy
              Credits understands the restrictions in Section 4 and will comply
              with them. [1798.140(w)]
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Consumer Requests
            </h2>
            <p class="text-base  tracking-loose">
              If United Green Energy Credits is contacted by a person with a
              request, inquiry or complaint regarding their Personal Information
              in connection with the Services, United Green Energy Credits shall
              promptly notify Customer and/or Company of such request, inquiry
              or complaint. United Green Energy Credits shall not respond to
              such request, inquiry or complaint directly, unless otherwise
              required by applicable law. Upon Customer’s and/or Company’s
              request, United Green Energy Credits shall provide Customer and/or
              Company with reasonable cooperation, assistance, information and
              access to Personal Information in its possession, custody or
              control as is necessary for Customer and/or Company to respond
              within any timeframe required by the CCPA to any verifiable
              consumer request to disclose Personal Information in a readily
              usable format or delete Personal Information pursuant to Sections
              1798.100-1798.105 of the California Civil Code.
              [1798.100-1798.105] If United Green Energy Credits intends to rely
              on an exception under Section 1798.105(d), United Green Energy
              Credits shall notify Company within the same timeframe of the
              intent.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Reasonable Security
            </h2>
            <p class="text-base  tracking-loose">
              United Green Energy Credits shall implement and maintain
              reasonable security procedures and practices https://www.United
              Green Energy Creditsenergy.com/terms-of-use/ appropriate to the
              nature of the information, to protect any “personal information”
              as defined in California Civil Code Section 1798.81.5 from
              unauthorized access, destruction, use, modification, or
              disclosure. [1798.81.5(c), 1798.150]
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Changes in Law
            </h2>
            <p class="text-base  tracking-loose">
              If any variation is required to this Addendum as a result of a
              change in the CCPA, then either party may provide written notice
              to the other party of that change. The parties will discuss and
              negotiate in good faith any necessary variations to this Addendum
              to address such changes.
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Severability
            </h2>
            <p class="text-base  tracking-loose">
              Should any provision of this Addendum be invalid or unenforceable,
              then the remainder of this Addendum shall remain valid and in
              force. The invalid or unenforceable provision shall be either (i)
              amended as necessary to ensure its validity and enforceability,
              while preserving the parties’ intentions as closely as possible
              or, if this is not possible, (ii) construed in a manner as if the
              invalid or unenforceable part had never been contained therein.
            </p>

            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              How We Use Your Personal Data
            </h2>
            <p class="text-base  tracking-loose">
              We use Personal Data to provide services to our clients for the
              following purposes:
            </p>

            <p class="text-base  tracking-loose">
              1. To personalize advertisements, offers, and/or website content
              and communications based on your preferences.
            </p>
            <p class="text-base  tracking-loose">
              2. To improve and develop the Services, including testing,
              research, analysis, and product development.
            </p>
            <p class="text-base  tracking-loose">
              3. To protect against or deter fraudulent, illegal, or harmful
              actions and maintain the safety, security, and integrity of our
              Services.
            </p>
            <p class="text-base  tracking-loose">
              4. To comply with our legal or contractual obligations and resolve
              disputes.
            </p>
            <p class="text-base  tracking-loose">
              5. To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </p>
            <p class="text-base  tracking-loose">
              6. For any other business purpose stated as otherwise set forth in
              applicable data privacy laws, such as the California Consumer
              Privacy Act (the “CCPA”).
            </p>
            <p class="text-base  tracking-loose">
              We will not collect additional categories of Personal Data or use
              the Personal Data we collected for materially different,
              unrelated, or incompatible purposes.
            </p>

            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              How We Share Your Personal Data
            </h2>
            <p class="text-base  italic tracking-loose">
              Disclosures of Personal Data for a Business Purpose
            </p>

            <p class="text-base  tracking-loose">
              We disclose your Personal Data to our clients for the following
              business purposes:
            </p>

            <p class="text-base  tracking-loose">
              1. Short-term, transient use of Personal Data that is not used by
              another party to build a consumer profile or otherwise alter your
              consumer experience outside the current interaction.
            </p>
            <p class="text-base  tracking-loose">
              2. Performing services on our behalf, including; maintaining or
              servicing accounts, providing customer service, processing or
              fulfilling orders and transactions, verifying customer
              information, processing payments, providing financing, providing
              advertising or marketing services, providing analytic services, or
              providing similar services on behalf of the business or service
              provider.
            </p>
            <p class="text-base  tracking-loose">
              3. Undertaking internal research for technological development and
              demonstration.
            </p>

            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Personal Data of Children
            </h2>
            <p class="text-base  tracking-loose">
              We do not knowingly collect or solicit Personal Data from children
              under 16. For further details on our privacy policies regarding
              data from children under 16, please see our general privacy policy
              published on the United Green Energy Credits website at
              <a href="/terms/english" class="text-orange">
                https://www.United Green Energy Creditsenergy.com/terms/
              </a>{" "}
            </p>
            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              California Resident Consumers’ Personal Data Rights
            </h2>
            <p class="text-base  tracking-loose">
              If you are a California resident, you have the rights outlined in
              this Statement. As given earlier in this Statement, United Green
              Energy Credits does not directly collect or control your Personal
              Data for the purposes of exercising your rights under the CCPA. If
              you have any questions about this section or any issues regarding
              your rights, please contact United Green Energy Credits at
              ‘webmaster “at” United Green Energy Credits.com’.
            </p>

            <p class="text-base  tracking-loose">
              Under the CCPA, and other similar privacy laws, you have the
              following rights regarding your Personal Data:
            </p>

            <p class="text-base  tracking-loose">
              1. To obtain a summary of your Personal Data.
            </p>
            <p class="text-base  tracking-loose">
              2. To obtain a copy of your Personal Data information.
            </p>
            <p class="text-base  tracking-loose">
              3. To have your Personal Data deleted.
            </p>
            <p class="text-base  tracking-loose">
              4. To opt out of the sale of your Personal Data.
            </p>
            <p class="text-base  tracking-loose">
              5. To prevent disclosure of your Personal Data to third parties.
            </p>
            <p class="text-base  tracking-loose">
              6. Any other Personal Data rights as provided for in the
              California Consumer Privacy Act.
            </p>

            <h2 class="mt-12 text-2xl md:text-4xl mb-2 text-emerald-600 tracking-tight">
              Contact Information
            </h2>
            <p class="text-base  tracking-loose">
              If you have any questions or comments about this Privacy Policy,
              the ways in which we collect and use your Personal Data, your
              choices and rights regarding such use, please do not hesitate to
              contact us at{" "}
              <a
                href="mailto:UnitedGreenEnergy@uetllc.com"
                class="text-orange-500"
              >
                UnitedGreenEnergy@uetllc.com.
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ccpa;
